<template>
  <router-view/>
</template>

<script>

  // add style scss
  import "@/assets/scss/main.scss";
  export default {
    name: "App"
  }

</script>

<style lang="scss">
</style>
