<template>
  <div id="message" class="hidden"></div>
  <div class="heart__container" v-on:click="favoriteAddRemove">
    <img
      v-if="favorite"
      class="heart heart-filled"
      src="../../assets/icones/coeur_plein.png"
      alt="icône coeur plein"
    />
    <img
      v-else
      class="heart heart-empty"
      src="../../assets/icones/coeur_vide.png"
      alt="icône coeur vide"
    />
  </div>
</template>

<script>
import FavoriteService from "@/services/favorite/FavoriteService";

export default {
  name: "HeartLayout",

  data() {
    return {};
  },

  // Definit des propriétés qui sont fournies par le parent
  props: {
    name: String,
    favorite: Boolean,
    id: Number,
  },

  methods: {
    async favoriteAddRemove(event) {
      // On empêche d'aller sur la page détail
      event.preventDefault();

      // On sélectionne l'élément message qui est lié au clic
      // (utile quand la fonction est appelée depuis la liste des chats et où il y a autant d'éléments message que de chartes)
      let currentT = event.currentTarget;
      let parentElmt = currentT.parentNode;
      let msgElmt = parentElmt.querySelector("#message");

      // Si le chat fait partie des favoris
      if (this.favorite) {

        const response = await FavoriteService.removeFromFavorites({
          cat_id: this.id,
        });
        if (response.code === 200) {
          this.$emit("update");
          // On fait apparaître le message sur l'interface utilisateur
          msgElmt.classList.toggle("hidden");
          msgElmt.innerHTML = this.name + " a été retiré de vos favoris";
          // On le fait disparaître au bout de 2 secondes
          setTimeout(() => { msgElmt.classList.toggle("hidden") }, 2000);
        } else {
          console.log("le chat n'a pas pu être retiré des favoris");
        }

        // Si le chat ne fait pas partie des favoris
      } else {
        console.log(
          "le chat " + this.name + " ne fait pas partie des favoris "
        );
        const response = await FavoriteService.addToFavorites({
          cat_id: this.id,
        });
        // En cas de réussite
        if (response.code === 200) {
          this.$emit("update");
          // On fait apparaître le message sur l'interface utilisateur
          msgElmt.classList.toggle("hidden")
          msgElmt.innerHTML = this.name + " a bien été ajouté à vos favoris";
          // On le fait disparaître au bout de 2 secondes
          setTimeout(() => { msgElmt.classList.toggle("hidden") }, 2000);
        } else {
          console.log("le chat n'a pas pu être ajouté aux favoris");
        }
      }
    },
  },
};
</script>

<style scoped lang="scss" >
</style>